import Vue from "vue";

const idea = {
    namespaced: true,
    state: {
        current: null,
        list: [],
        keyMappedFullIdeasObject: {},
        listLoaded: false,
        listPaginationProperties: {
            lastPage: false,
            currentPage: 1,
        },
        listByCategory: {
            0: {
                lastPage: false,
                currentPage: 1,
                list: [],
            },
        },
    },
    getters: {
        ideasLoaded: (state) => state.listLoaded,
        currentIdea: (state) => state.current,
        byId: (state) => (id) => state.keyMappedFullIdeasObject[id],
        listOfIdeas: (state) => state.list,
        listPaginationProperties: (state) => state.listPaginationProperties,
        listByCategoryId: (state) => (category_id) => state.listByCategory[category_id],
    },

    mutations: {
        ADD_IDEA_TO_LIST(state, idea) {
            state.list = state.list.filter((elem) => elem.id != idea.id);
            if (!idea.comments_count) idea.comments_count = 0;
            state.list.unshift(idea);
            let category_id = idea.category_id;
            if (category_id) {
                let category = this.state.category.list.find(
                    (category) => category.id == category_id
                );
                if (category) category.ideas_count++;
            }
        },
        REMOVE_IDEA(state, id) {
            const newStateList = state.list.filter((elem) => Number(elem.id) !== Number(id));
            Vue.set(state, "list", newStateList);
        },
        MASS_REMOVE_IDEA_FROM_LIST(state, idsArray) {
            const newStateList = state.list.filter((elem) => !idsArray.includes(Number(elem.id)));
            Vue.set(state, "list", newStateList);
        },
        REMOVE_IDEA_FROM_PREVIOUS_CATEGORY(state, idea) {
            let category_id = idea.category_id;
            if (category_id) {
                let category = this.state.category.list.find(
                    (category) => category.id == category_id
                );
                if (category && category.ideas_count && category.ideas_count > 0)
                    category.ideas_count--;
            }
        },
        REMOVE_IDEA_FROM_CATEGORY_LIST(state, idea) {
            if (state.listByCategory[idea.category_id]) {
                state.listByCategory[idea.category_id].list = state.listByCategory[
                    idea.category_id
                ].list.filter((subIdea) => Number(subIdea.id) !== Number(idea.id));
            }
        },
        ADD_IDEA_TO_CATEGORY_LIST(state, idea) {
            if (idea.category_id && state.listByCategory[idea.category_id]) {
                if (
                    !state.listByCategory[idea.category_id].list.find(
                        (subIdea) => Number(subIdea.id) !== Number(idea.id)
                    )
                )
                    state.listByCategory[idea.category_id].list.unshift(idea);
            }
        },
        SAVE_CURRENT(state, idea) {
            state.current = idea;
        },
        SET_IDEA_TO_MAPPED_OBJECT(state, idea) {
            Vue.set(state.keyMappedFullIdeasObject, idea.id, idea);
        },
        REMOVE_IDEA_FROM_MAPPED_OBJECT(state, id) {
            Vue.delete(state.keyMappedFullIdeasObject, id);
        },
        setList(state, comments) {
            state.list = comments;
        },
        OVERWRITE_IDEAS_LIST(state, data) {
            state.list = data.data;
            if (Number(data.meta.last_page) === Number(data.meta.current_page)) {
                state.listPaginationProperties.lastPage = true;
            } else {
                state.listPaginationProperties.lastPage = false;
                state.listPaginationProperties.currentPage = 2;
            }
        },
        ADD_TO_IDEAS_LIST(state, data) {
            data.data.forEach((idea) => {
                if (!state.list.find((subIdea) => Number(subIdea.id) === Number(idea.id)))
                    state.list.push(idea);
            });
            if (Number(data.meta.last_page) === Number(data.meta.current_page)) {
                state.listPaginationProperties.lastPage = true;
            } else {
                state.listPaginationProperties.lastPage = false;
                state.listPaginationProperties.currentPage++;
            }
        },
        ADD_TO_CATEGORY_IDEAS_LIST(state, data) {
            if (!state.listByCategory[data.category_id]) {
                state.listByCategory[data.category_id] = {
                    currentPage: 1,
                    list: [],
                    lastPage: false,
                };
            }
            const categoryData = state.listByCategory[data.category_id];
            data.data.forEach((idea) => {
                if (!categoryData.list.find((subIdea) => Number(subIdea.id) === Number(idea.id))) {
                    categoryData.list.push(idea);
                }
            });
            const currentPage = Number(data.meta.current_page);
            const lastPage = Number(data.meta.last_page);
            categoryData.lastPage = currentPage >= lastPage;
            if (!categoryData.lastPage) {
                categoryData.currentPage++;
            }
        },
        SET_LIST_LOADED(state, status = true) {
            state.listLoaded = status;
        },

        INCREMENT_COMMENT(state, { ideaId, isRoot }) {
            if (isRoot) {
                state.keyMappedFullIdeasObject[ideaId].root_comments_count++;
            }
            state.keyMappedFullIdeasObject[ideaId].comments_count++;
            const idea = state.list.find((idea) => Number(idea.id) === Number(ideaId));
            if (idea) {
                idea.comments_count++;
            }
        },
        DECREMENT_COMMENTS(state, { ideaId, isRoot, children }) {
            if (isRoot) {
                state.keyMappedFullIdeasObject[ideaId].root_comments_count--;
            }
            state.keyMappedFullIdeasObject[ideaId].comments_count -= children + 1;
            const idea = state.list.find((idea) => Number(idea.id) === Number(ideaId));
            if (idea) {
                idea.comments_count -= children + 1;
            }
        },
        OVERWRITE_CATEGORY_IDEAS_LIST(state, data) {
            if (!state.listByCategory[data.category_id]) {
                state.listByCategory[data.category_id] = {
                    currentPage: 1,
                    list: [],
                    lastPage: false,
                };
            }

            state.listByCategory[data.category_id].list = data.data;

            const currentPage = Number(data.meta.current_page);
            const lastPage = Number(data.meta.last_page);

            state.listByCategory[data.category_id].lastPage = currentPage >= lastPage;

            if (currentPage < lastPage) {
                state.listByCategory[data.category_id].currentPage = currentPage + 1;
            } else {
                state.listByCategory[data.category_id].currentPage = currentPage;
            }
        },
    },
    actions: {
        createOrUpdate(context, idea) {
            if (idea.id) {
                context.commit("REMOVE_IDEA_FROM_PREVIOUS_CATEGORY", idea);
            }
            let request = this.$app.$api.saveIdea(idea);
            request.then((response) => {
                if (response.data.is_anon) {
                    response.data.user = {
                        avatar: null,
                        name: null,
                        last_name: null,
                    };
                }
                context.commit("SAVE_CURRENT", response.data);
                context.commit("SET_IDEA_TO_MAPPED_OBJECT", response.data);
            });
            return request;
        },
        loadIdea(context, id) {
            let request = this.$app.$api.getIdea(id);
            request.then((response) => {
                context.commit("SAVE_CURRENT", response.data?.data);
                context.commit("SET_IDEA_TO_MAPPED_OBJECT", response.data?.data);
            });
            return request;
        },
        deleteIdea(context, idea) {
            let request = this.$app.$api.deleteIdea(idea.id);
            request.then(() => {
                context.commit("REMOVE_IDEA", idea.id);
                context.commit("REMOVE_IDEA_FROM_MAPPED_OBJECT", idea.id);
                if (idea.category_id) {
                    context.commit("REMOVE_IDEA_FROM_CATEGORY_LIST", idea);
                }
            });
            return request;
        },
        statistics(context, data) {
            let request = this.$app.$api.statistics(data);
            return request;
        },
        loadIdeasList(context, data) {
            context.commit("SET_LIST_LOADED", false);
            data.page = data.page ? data.page : context.state.listPaginationProperties.currentPage;
            let request = this.$app.$api.loadIdeas(data);
            request
                .then((response) => {
                    if (data.page > 1) {
                        context.commit("ADD_TO_IDEAS_LIST", response.data);
                    } else {
                        context.commit("OVERWRITE_IDEAS_LIST", response.data);
                    }
                    return true;
                })
                .finally(() => {
                    context.commit("SET_LIST_LOADED", true);
                });
        },
        moderation(context, data) {
            let request = this.$app.$api.moderation(data);
            return request;
        },
        confirmIdea(context, data) {
            let request = this.$app.$api.confirm(data);
            return request;
        },
        rejectIdea(context, data) {
            let request = this.$app.$api.reject(data);
            return request;
        },
        getStatuses(context, data) {
            let request = this.$app.$api.statuses(data);
            return request;
        },
        getCategories(context, data) {
            let request = this.$app.$api.categories(data);
            return request;
        },
        changeModerationMode(context, data) {
            let request = this.$app.$api.changeModerationMode(data);
            return request;
        },
        getModerationMode(context, data) {
            let request = this.$app.$api.getModerationMode(data);
            return request;
        },
        loadListByCategory(context, data) {
            data.page = context.state.listByCategory[data.categoryId]?.currentPage || 1;
            let request = this.$app.$api.loadIdeas(data);
            request.then((response) => {
                const payload = response.data;
                payload.category_id = data.categoryId;
                if (data.page > 1) {
                    context.commit("ADD_TO_CATEGORY_IDEAS_LIST", payload);
                } else {
                    context.commit("SET_LIST_LOADED", false);
                    context.commit("OVERWRITE_CATEGORY_IDEAS_LIST", payload);
                }
                return true;
            });

            return request;
        },
        removeByCategoryId({ state, commit }, category_id) {
            const ids = state.list
                .filter((elem) => Number(elem.category_id) === Number(category_id))
                .map((category) => category.id);
            commit("MASS_REMOVE_IDEA_FROM_LIST", ids);
            ids.forEach((id) => {
                commit("REMOVE_IDEA_FROM_MAPPED_OBJECT", id);
            });
        },
        setReaction({ commit }, { id, hasLike }) {
            let request;
            if (hasLike) {
                request = this.$app.$api.reactions.unLikeIdea(id);
            } else {
                request = this.$app.$api.reactions.likeIdea(id);
            }
            request.then((response) => {
                commit("SET_IDEA_TO_MAPPED_OBJECT", response.data.data);
            });
            return request;
        },
    },
};

export default idea;
