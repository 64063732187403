const category = {
    namespaced: true,
    state: {
        current: null,
        list: [],
        listLoaded: false,
        listProperties: {
            lastPage: false,
            currentPage: 1,
            loaded: false,
        },
        children: [],
        categoriesByParentId: [],
    },
    getters: {
        categoriesLoaded: (state) => state.listLoaded,
        currentCategory: (state) => state.current,
        byId: (state) => (id) => state.list.find((elem) => elem.id == id),
        listOfCategories: (state) => state.list,
        listCategoriesByParentId: (state) => state.categoriesByParentId,
        propOfListOfCategories: (state) => state.listProperties,
    },

    mutations: {
        ADD_CATEGORY_TO_LIST(state, category) {
            //for updating
            let parent = state.list.find(
                (elem) => elem.children && elem.children.includes(category.id)
            );
            if (parent) {
                parent.children = parent.children.filter((elem) => elem != category.id);
            }
            state.list = state.list.filter((elem) => elem.id != category.id);

            // for both
            if (state.list.length) {
                let parent_id = category.parent_id || 0;
                if (!state.list.find((elem) => elem.id == parent_id).children) {
                    state.list.find((elem) => elem.id == parent_id).children = [];
                }
                state.list.find((elem) => elem.id == parent_id).children.push(category.id);
            }
            state.list.unshift(category);
        },
        REMOVE_CATEGORY(state, id) {
            let parent = state.list.find((elem) => elem.children && elem.children.includes(id));
            if (parent) {
                parent.children = parent.children.filter((elem) => elem != id);
            }
            state.list = state.list.filter((elem) => elem.id != id);
            state.categoriesByParentId = state.categoriesByParentId.filter((category) => category.id !== id);
        },
        SAVE_CURRENT(state, loaded) {
            state.current = loaded;
        },
        SAVE_CATEGORIES_LIST(state, data) {
            state.list = data;
            state.listProperties.loaded = true;
        },
        SAVE_CATEGORIES_BY_PARENT_ID(state, categories) {
            state.categoriesByParentId = categories;
        },
        SET_LIST_LOADED(state, status = true) {
            state.listLoaded = status;
        },
    },
    actions: {
        save(context, category) {
            let request = this.$app.$api.saveCategory(category);
            request.then((response) => {
                context.commit("ADD_CATEGORY_TO_LIST", response.data);
            });
            return request;
        },
        loadCurrent(context, id) {
            let request = this.$app.$api.getCategory(id);
            request.then((response) => {
                context.commit("SAVE_CURRENT", response.data);
            });
            return request;
        },
        delete(context, categoryId) {
            context.state.children = [];

            let children = helpers.getIds(context.state.list, categoryId);
            let request = this.$app.$api.deleteCategory(categoryId);
            request.then(() => {
                if (children.length) {
                    children.forEach((id) => {
                        context.dispatch("idea/removeByCategoryId", id, { root: true });
                        context.commit("REMOVE_CATEGORY", id);
                    });
                }
                context.dispatch("idea/removeByCategoryId", categoryId, { root: true });
                context.commit("REMOVE_CATEGORY", categoryId);
            });
        },
        loadCategoriesList(context) {
            context.commit("SET_LIST_LOADED", false);
            let request = this.$app.$api.getCategoriesList();
            request
                .then((response) => {
                    context.dispatch("initTreeCategories", response.data);
                })
                .finally(() => {
                    context.commit("SET_LIST_LOADED", true);
                });
            return request;
        },
        loadCategoriesListByParentId(context, categoryId) {
            return this.$app.$api.getCategoriesByParentId(categoryId)
                .then((response) => {
                context.commit("SAVE_CATEGORIES_BY_PARENT_ID", response.data);
            });
        },
        initTreeCategories({ commit }, categories) {
            let rootCategories = [];
            categories.forEach((elem) => {
                let parentId = parseInt(elem.parent_id) || 0;
                if (parentId > 0) {
                    if (!categories.find((elem) => elem.id == parentId).children) {
                        categories.find((elem) => elem.id == parentId).children = [];
                    }
                    categories.find((parent) => parent.id == parentId).children.push(elem.id);
                } else {
                    rootCategories.push(elem.id);
                }
            });
            // root categories and ideas
            categories.push({ id: 0 });
            if (rootCategories.length > 0) {
                categories.find((elem) => elem.id === 0).children = rootCategories;
            }
            commit("SAVE_CATEGORIES_LIST", categories);
        },
    },
};
const helpers = {
    children: [],
    getIds(list, id) {
        this.getChildrenIds(list, [parseInt(id)]);
        return this.children;
    },
    getChildrenIds(list, ids) {
        let children = [];
        list.forEach((category) => {
            if (ids.includes(category.parent_id)) {
                children.push(category.id);
            }
        });
        if (children.length) {
            this.children = this.children.concat(children);
            this.getChildrenIds(list, children);
        }
    },
};

export default category;
